.card-info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	border-color: rgba(237, 239, 242, 1) !important;
}

.card-configuration {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	border-color: rgba(237, 239, 242, 1) !important;
}

.configuration-option {
	gap: 13px;
	display: flex;
	flex-direction: column;
}

.configuration-row {
	/* margin-bottom: 1.3rem; */
	/* background-color: red; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.configuration-row .MuiBox-root {
	display: flex;
	flex-direction: column;
	gap: 0px;
	width: 70%;
}

.configuration-row .MuiInputBase-input {
	padding: 6px 16px;
	font-size: 16px;
	font-weight: 400;
}
.configuration-row .MuiOutlinedInput-notchedOutline {
	border-color: #dbdee1;
	border-radius: 8px;
}

.text-subheader {
	color: #00000099;
}

.card-tab {
	background-color: #fff !important;
	border: 1px solid #edeff2 !important;
	border-radius: 6px 6px 0px 0px !important;
	margin-right: 1.5rem !important;
	width: 160px !important;
	color: #000;
	text-transform: none !important;
	font-size: 16px !important;
}

.sub-card-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
}

.sub-card-tab {
	background-color: #fff !important;
	border: 1px solid #edeff2 !important;
	border-radius: 4px !important;
	color: #858585;
	text-transform: none !important;
	font-size: 12px !important;
	margin-bottom: 5px !important;
	padding: 0 !important;
	min-height: 30px !important;
	max-width: 167px !important;
	font-weight: 400 !important;
}
